import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { media } from "../utils/breakpoints";
import LanguageDropdown from "./LanguageDropdown";
import LocalizedLink from "./LocalizedLink";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../utils/hooks";
import { BlueButton } from "./shared";


const NavWrap = styled.div<any>``;

const BurgerButtonWrap = styled.div<any>`
  z-index: 1;
  width: 42px;
  height: 40px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  cursor: pointer;
  display: none;

  ${media.lessThan("sm")} {
    display: block;

    ${(props) =>
      props.isOpen &&
      `
      width: 30px;
      margin-right: 8px;
    `}
  }

  span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 14px;
    }

    &:nth-child(4) {
      top: 28px;
    }

    ${(props) =>
      props.isOpen &&
      `
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    `}
  }
`;
const linkStyles = css`
  font-size: 15px;
  line-height: 19px;
  margin-right: 48px;

  ${media.lessThan("md")} {
    margin-right: 26px;
  }

  ${media.lessThan("sm")} {
    line-height: 24px;
  }
`;
const StyledLink = styled.a`
  ${linkStyles}
`;
const StyledLocalizedLink = styled(LocalizedLink)<
  React.AnchorHTMLAttributes<any>
>`
  ${linkStyles}
`;

const NavBlock = styled.nav<any>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  transition: 0.25s ease-in-out;

  ${media.lessThan("sm")} {
    position: absolute;
    background: ${(props) => props.theme.colors.bgLighter};
    top: 0;
    flex-direction: column;
    align-items: flex-start;
    width: 0;
    opacity: 0;
    right: 133px;
    box-shadow: 0 8px 6px -6px black;
    padding: 80px 16px 16px;
    visibility: hidden;

    ${(props) =>
      props.isOpen &&
      `
      opacity: 1;
      width: 100%;
      right: 0;
      visibility: visible;
    `}
  }
`;

const LinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 2em 0 0;
`;

const Nav: React.FC<GlobalProps> = (props) => {
  const { t } = useTranslation();
  const ref = useRef();

  const [open, setOpen] = useState(false);
  const handleClickOutside = useCallback(() => setOpen(false), [setOpen]);
  useOnClickOutside(ref, handleClickOutside);

  return (
    <NavWrap ref={ref}>
      <BurgerButtonWrap onClick={() => setOpen(!open)} isOpen={open}>
        <span />
        <span />
        <span />
        <span />
      </BurgerButtonWrap>
      <NavBlock isOpen={open}>
        <StyledLocalizedLink to="/features" onClick={handleClickOutside}>
          {t(`features`)}
        </StyledLocalizedLink>
        <StyledLink
          title={t(`docs`)}
          href="https://docs.vigor.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`docs`)}
        </StyledLink>
        <StyledLocalizedLink to="/community" onClick={handleClickOutside}>
          {t(`community`)}
        </StyledLocalizedLink>
        <StyledLocalizedLink to="/faq" onClick={handleClickOutside}>
          {t(`faqShort`)}
        </StyledLocalizedLink>
        <LinkWrap>
          <BlueButton as="localizedLink" to={"https://app.vigor.ai"}>
            {t(`app`)}
          </BlueButton>
        </LinkWrap>
        <LanguageDropdown path={props.path} />
      </NavBlock>
    </NavWrap>
  );
};

export default Nav;
