// LCID strings https://www.science.co.il/language/Locale-codes.php
module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  zh: {
    path: `zh`,
    locale: `zh-CN`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `zh`,
    ogLanguage: `zh_CN`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  ko: {
    path: `ko`,
    locale: `ko`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `ko`,
    ogLanguage: `ko`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  es: {
    path: `es`,
    locale: `es`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `es`,
    ogLanguage: `es`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  fr: {
    path: `fr`,
    locale: `fr`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `fr`,
    ogLanguage: `fr`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  it: {
    path: `it`,
    locale: `it`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `it`,
    ogLanguage: `it`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  de: {
    path: `de`,
    locale: `de-DE`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `de`,
    ogLanguage: `de_DE`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  tr: {
    path: `tr`,
    locale: `tr`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `tr`,
    ogLanguage: `tr`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  id: {
    path: `id`,
    locale: `id`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `id`,
    ogLanguage: `id`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  pt: {
    path: `pt`,
    locale: `pt`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `pt`,
    ogLanguage: `pt`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  fil: {
    path: `fil`,
    locale: `fil`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `fil`,
    ogLanguage: `fil`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
  vi: {
    path: `vi`,
    locale: `vi`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `vi`,
    ogLanguage: `vi`,
    defaultTitle: `Vigor Protocol`,
    defaultDescription: `Vigor Protocol`,
  },
}
