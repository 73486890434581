import { useState, useEffect } from "react";
import {
  TConfigRow,
  TGlobalStatsRow,
  TOracleTSeriesRow,
  VIGOR_BUSINESS_CONTRACT,
  VIGOR_ORACLE_CONTRACT,
} from "./vigor-types";
import { fetchRows } from "./eos";

export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const useFetchGlobalVigorStats = () => {
  const [data, setData] = useState<{
    global?: TGlobalStatsRow;
    config?: TConfigRow;
  }>({
    global: undefined,
    config: undefined,
  });

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    async function fetchData() {
      try {
        const [globalRows, configRows] = await Promise.all([
          fetchRows<TGlobalStatsRow>({
            code: VIGOR_BUSINESS_CONTRACT,
            scope: VIGOR_BUSINESS_CONTRACT,
            table: `globalstats`,
            limit: 1,
          }),
          fetchRows<TConfigRow>({
            code: VIGOR_BUSINESS_CONTRACT,
            scope: VIGOR_BUSINESS_CONTRACT,
            table: `config`,
            limit: 1,
          }),
        ]);

        setData({
          global: globalRows[0],
          config: configRows[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return data;
};
