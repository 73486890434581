import React from 'react';

type Props = {
  variant:string,
  height: number,
  inverted?: boolean,
  primary?: boolean,
  horizontal?: boolean,
  textOnly?:boolean,
  badgeOnly?:boolean
};

const vigorLogoPrimaryHorizontalInverted = (height: number) =>
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 165 75.2">
    <title>VIGOR Protocol</title>
    <path
      fill="#fff"
      d="M24 .5L9.5 22.8c-.5.9-.7 2-.4 3l15.5 45.8c0 .3.5.3.6 0l15.2-45.9c.3-1 .2-2-.3-2.9L25.8.5a1 1 0 00-1.8 0z"
    />
    <path
      fill="#4468cc"
      d="M41.8 28.1l-15.7 47 23.4-36.6c.2-.3.2-.6 0-.9L43.2 28a.8.8 0 00-1.4.2zM7.8 27.9l15.7 47L.1 38.3a.8.8 0 010-.8l6.3-9.7a.8.8 0 011.4.2z"
    />
    <g fill="#fff">
      <path d="M64.5 27.9h5.2l5.6 15 5.6-15h5l-8.6 21.5H73L64.5 28zM89.4 27.9h4.7v21.4h-4.7V27.9zM97.5 38.6c0-6.1 4.8-11 11.3-11 3.8 0 6.1 1 8.4 2.8l-3 3.6a7.9 7.9 0 00-5.6-2.1c-3.4 0-6.1 3-6.1 6.6 0 4 2.6 6.9 6.4 6.9 1.7 0 3.3-.4 4.5-1.3v-3h-4.8V37h9.3v9.3a13.9 13.9 0 01-9.1 3.4c-6.7 0-11.3-4.7-11.3-11zM120.5 38.6c0-6.1 4.8-11 11.4-11a11 11 0 0111.4 11c0 6-4.8 11-11.4 11a11 11 0 01-11.4-11zm17.8 0c0-3.7-2.6-6.8-6.4-6.8-3.8 0-6.5 3-6.5 6.7s2.7 6.8 6.5 6.8 6.4-3 6.4-6.7zM146.5 27.9h9.8c2.7 0 4.8.7 6.2 2.2a6.7 6.7 0 011.8 4.8v.1c0 3.5-1.8 5.6-4.6 6.6l5.3 7.7h-5.5l-4.6-6.9h-3.7v6.9h-4.7V27.9zm9.4 10.4c2.3 0 3.7-1.2 3.7-3v-.1c0-2-1.4-3-3.7-3h-4.7v6h4.8z" />
    </g>
  </svg>
;

const vigorLogoTextInverted = (height: number) =>
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="64 0 165 75.2">
    <g fill="#fff">
      <path d="M64.5 27.9h5.2l5.6 15 5.6-15h5l-8.6 21.5H73L64.5 28zM89.4 27.9h4.7v21.4h-4.7V27.9zM97.5 38.6c0-6.1 4.8-11 11.3-11 3.8 0 6.1 1 8.4 2.8l-3 3.6a7.9 7.9 0 00-5.6-2.1c-3.4 0-6.1 3-6.1 6.6 0 4 2.6 6.9 6.4 6.9 1.7 0 3.3-.4 4.5-1.3v-3h-4.8V37h9.3v9.3a13.9 13.9 0 01-9.1 3.4c-6.7 0-11.3-4.7-11.3-11zM120.5 38.6c0-6.1 4.8-11 11.4-11a11 11 0 0111.4 11c0 6-4.8 11-11.4 11a11 11 0 01-11.4-11zm17.8 0c0-3.7-2.6-6.8-6.4-6.8-3.8 0-6.5 3-6.5 6.7s2.7 6.8 6.5 6.8 6.4-3 6.4-6.7zM146.5 27.9h9.8c2.7 0 4.8.7 6.2 2.2a6.7 6.7 0 011.8 4.8v.1c0 3.5-1.8 5.6-4.6 6.6l5.3 7.7h-5.5l-4.6-6.9h-3.7v6.9h-4.7V27.9zm9.4 10.4c2.3 0 3.7-1.2 3.7-3v-.1c0-2-1.4-3-3.7-3h-4.7v6h4.8z" />
    </g>
  </svg>
;

const vigorLogoBadgeInverted = (height: number) =>
  <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 165 75.2">
    <path
      fill="#fff"
      d="M24 .5L9.5 22.8c-.5.9-.7 2-.4 3l15.5 45.8c0 .3.5.3.6 0l15.2-45.9c.3-1 .2-2-.3-2.9L25.8.5a1 1 0 00-1.8 0z"
    />
    <path
      fill="#4468cc"
      d="M41.8 28.1l-15.7 47 23.4-36.6c.2-.3.2-.6 0-.9L43.2 28a.8.8 0 00-1.4.2zM7.8 27.9l15.7 47L.1 38.3a.8.8 0 010-.8l6.3-9.7a.8.8 0 011.4.2z"
    />
  </svg>
;

const vigorLogoSpecialHorizontal = (height: number) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="116px" height={height} viewBox="0 0 116 54" style={{ width: '75%' }}>
    <title>VIGOR Protocol</title>
    <g id="logo-vigor">
      <path d="M16.80966,1.03775 L6.64216,16.68135 C6.26136,17.28405 6.16896,18.02605 6.39086,18.70365 L17.19606,50.80705 C17.26676,51.02335 17.57266,51.02405 17.64406,50.80775 L28.29736,18.71555 C28.52486,18.03095 28.43106,17.28055 28.04256,16.67295 L18.03326,1.03425 C17.74696,0.58695 17.09316,0.58835 16.80966,1.03775" id="Fill-1" fill="#FFFFFF"/>
      <path d="M29.2873,20.37252 L18.2616,53.31452 L34.6521,27.61682 C34.7648,27.43972 34.7641,27.21292 34.65,27.03722 L30.2449,20.25072 C30.0013,19.87482 29.4301,19.94762 29.2873,20.37252" id="Fill-4" fill="#1E212E"/>
      <path d="M5.44901,20.23238 L16.47471,53.17368 L0.08421,27.47668 C-0.02849,27.29958 -0.02779,27.07208 0.08631,26.89708 L4.49141,20.11058 C4.73501,19.73398 5.30621,19.80678 5.44901,20.23238" id="Fill-7" fill="#1E212E"/>
      <polygon id="Fill-9" fill="#FFFFFF" points="45.11185 20.2146 48.75115 20.2146 52.66835 30.7671 56.58555 20.2146 60.13875 20.2146 54.08095 35.3059 51.16965 35.3059"/>
      <polygon id="Fill-11" fill="#FFFFFF" points="62.6024 35.19803 65.8994 35.19803 65.8994 20.21453 62.6024 20.21453"/>
      <path d="M68.2829,27.74919 L68.2829,27.70649 C68.2829,23.44699 71.6002,19.95819 76.1383,19.95819 C78.8354,19.95819 80.4615,20.68549 82.0246,22.01339 L79.9484,24.51729 C78.7927,23.55409 77.7651,22.99759 76.0312,22.99759 C73.6344,22.99759 71.729,25.11649 71.729,27.66379 L71.729,27.70649 C71.729,30.44699 73.612,32.45879 76.2664,32.45879 C77.4655,32.45879 78.5351,32.15919 79.3702,31.55929 L79.3702,29.41939 L76.0529,29.41939 L76.0529,26.57179 L82.5601,26.57179 L82.5601,33.07899 C81.018,34.38449 78.8998,35.45549 76.16,35.45549 C71.4931,35.45549 68.2829,32.18019 68.2829,27.74919" id="Fill-13" fill="#FFFFFF"/>
      <path d="M96.84619,27.74919 L96.84619,27.70649 C96.84619,25.13819 94.96249,22.99759 92.30879,22.99759 C89.65439,22.99759 87.81339,25.09479 87.81339,27.66379 L87.81339,27.70649 C87.81339,30.27479 89.69639,32.41539 92.35079,32.41539 C95.00519,32.41539 96.84619,30.31819 96.84619,27.74919 M84.36729,27.74919 L84.36729,27.70649 C84.36729,23.44699 87.72799,19.95819 92.35079,19.95819 C96.97499,19.95819 100.29229,23.40429 100.29229,27.66379 L100.29229,27.70649 C100.29229,31.96599 96.93159,35.45549 92.30879,35.45549 C87.68459,35.45549 84.36729,32.00869 84.36729,27.74919" id="Fill-14" fill="#FFFFFF"/>
      <path d="M109.16248,27.49285 C110.76828,27.49285 111.68808,26.63605 111.68808,25.37395 L111.68808,25.33055 C111.68808,23.91795 110.70388,23.19065 109.09808,23.19065 L105.82278,23.19065 L105.82278,27.49285 L109.16248,27.49285 Z M102.52718,20.21495 L109.37668,20.21495 C111.28138,20.21495 112.75908,20.74975 113.74328,21.73395 C114.57768,22.56975 115.02778,23.74645 115.02778,25.15905 L115.02778,25.20245 C115.02778,27.62095 113.72228,29.14065 111.81688,29.84695 L115.47718,35.19845 L111.62438,35.19845 L108.41348,30.40345 L105.82278,30.40345 L105.82278,35.19845 L102.52718,35.19845 L102.52718,20.21495 Z" id="Fill-15" fill="#FFFFFF"/>
    </g>
  </svg>
;

const VigorLogo: React.FC<Props> = (props: Props) => {
  // color
  if (props.variant === 'primary') {

  }

  if (props.variant === 'secondary') {

  }

  if (props.variant === 'special') {
    return vigorLogoSpecialHorizontal(props.height);
  }

  if (props.textOnly) {
    return vigorLogoTextInverted(props.height);
  }

  if (props.badgeOnly) {
    return vigorLogoBadgeInverted(props.height);
  }

  return vigorLogoPrimaryHorizontalInverted(props.height);

  // if (!props.inverted) {
  //   if (!props.horizontal) {
  //     if (props.primary) {
      // }
    // }
  // }
};

export default VigorLogo;
