export type TConfigRow = {
  mintesprice: number;
}

export type TGlobalStatsRow = {
  solvency: string;
  valueofcol: string;
  valueofins: string;
  scale: string;
  svalueofcole: string;
  svalueofins: string;
  stressins: string;
  svalueofcoleavg: string;
  svalueofinsavg: string;
  raroc: string;
  premiums: string;
  scr: string;
  earnrate: string;
  savingsrate: string;
  lastupdate: string;
  fee: string;
  totaldebt: string;
  insurance: string[]; // "1086.0791 EOS"
  collateral: string[]; // "2944.4236 EOS"
  l_solvency: string;
  l_valueofcol: string;
  l_scale: string;
  l_svalueofcole: string;
  l_svalueofins: string;
  l_svalueofcoleavg: string;
  l_svalueofinsavg: string;
  l_premiums: string;
  l_scr: string;
  l_fee: string;
  l_totaldebt: string;
  l_collateral: string[]; //  "24.0000 EOS"
  availability: string[]; // time_point[]
};

export type TOracleTSeriesRow = {
  freq: number;
  timestamp: string; // "2020-05-07T09:26:57.500";
  price: number[];
  returns: [];
  correlation_matrix: any[];
  vol: number;
};

export const VIGOR_BUSINESS_CONTRACT = `vigorlending`
export const VIGOR_ORACLE_CONTRACT = `vigoraclehub`
