import styled, { css } from "styled-components";
import React from "react";
import LocalizedLink from "../LocalizedLink";

export const BlueButtonStyles = css`
  display: ${props => ((props as any).fullWidth ? "block" : "inline")};
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-align: center;
  height: 40px;
  padding: 11px 30px;
  margin: ${props => (props as any).margin || "0"};
  width: ${props => ((props as any).fullWidth ? "100%" : "initial")};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLighter};
  }
`;

const BlueButtonButton = styled.button`
  ${BlueButtonStyles}
`;
const BlueButtonAnchor = styled.a`
  ${BlueButtonStyles}
`;

type BlueButtonProps = {
  as: "a" | "button" | "localizedLink";
  margin?: string;
  fullWidth?: boolean;
  to?: string;
};
export const BlueButton: React.FC<BlueButtonProps &
  React.HTMLProps<HTMLButtonElement> &
  React.HTMLProps<HTMLAnchorElement>> = ({ as, ...otherProps }) => {
  if (as === "a") return <BlueButtonAnchor {...(otherProps as any)} />;
  else if (as === `localizedLink`) {
    return <LocalizedLink css={BlueButtonStyles} {...(otherProps as any)} />;
  }

  return <BlueButtonButton {...(otherProps as any)} />;
};
