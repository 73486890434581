import React from "react";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import Toolbar from "../components/Toolbar";
import GlobalStyles from "../components/GlobalStyles";
import withI18next from "../i18n/withI18next";
import theme from "../utils/theme";
import Footer from "../components/Footer";
import {media} from "../utils/breakpoints";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PageHeader = styled.header`
  width: 100%;
    
  ${media.lessThan('sm')} {
    height: 162px;
  }
`;

const MainSection = styled.main`
  width: 100%;
  max-width: 1240px;
  margin-bottom: 48px;
`;

const Layout: React.FC<GlobalProps> = props => {
  return (
    <ThemeProvider theme={theme}>
      <Page>
        <GlobalStyles />
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap"
            rel="stylesheet"
          />
          <title>VIGOR Protocol - Peer-to-Peer Lending - Borrow, Lend, Insure, Earn </title>
          <meta name="title" content="VIGOR Protocol - Peer-to-Peer Lending - Borrow, Lend, Insure"/>
          <meta name="description" content="Borrow, lend, insure, and vote through open source smart contracts."/>
          <meta name="keywords" content="vigor, crypto, borrow, earn, lend, insure, peer-to-peer, lending, defi"/>
          <meta name="robots" content="index, follow"/>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
          <meta name="language" content="English"/>
          <meta name="author" content="VIGOR DAC"/>
          <meta name="google-site-verification" content="Lg1Wpfo-keIvqCQhijp8CifrazG_4swB3J8iXWljV0w" />
        </Helmet>
        <PageHeader>
          <Toolbar {...props} />
        </PageHeader>
        <MainSection>{props.children}</MainSection>
        <Footer />
      </Page>
    </ThemeProvider>
  );
};

export default withI18next()(Layout);
