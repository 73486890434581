import React, {useEffect, useState} from "react";
import styled from "styled-components";
import LocalizedLink from "./LocalizedLink";
import VigorLogo from "./VigorLogo";
import Nav from "./Nav";
import {media} from "../utils/breakpoints";
import { throttle } from "lodash";

const Wrapper = styled.div<any>`
  display: flex;
  width: 100%;
  max-width: 1240px;
  align-items: center;
  margin: 0 auto 71px auto;
  padding: 16px;
  transition: background .25s;
  
  ${media.lessThan('sm')} {
    position: fixed;
    
    ${props => props.showBackgroundColor && `
      background: #1E212E;
      box-shadow: 0 8px 6px -6px black;
      padding: 8px 16px;
    `}
  }
`;

const Spacer = styled.div`
  flex: 1 1 auto;
`;

const StyledLocalizedLink = styled(LocalizedLink)`
  z-index: 1;
  position: relative;
`;

const wasScrolledDownEnough = () => {
  return window.scrollY > 0;
};

const handleInput = (setShowBackgroundColor:any) => {
  return throttle(() => {
    requestAnimationFrame(() => {
      if (wasScrolledDownEnough()) {
        setShowBackgroundColor(true);
        return;
      }

      setShowBackgroundColor(false);
    });
  }, 100);
};

const Toolbar: React.FC<GlobalProps> = props => {
  const [ showBackgroundColor, setShowBackgroundColor ] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleInput(setShowBackgroundColor));
  }, []);

  return (
    <Wrapper showBackgroundColor={showBackgroundColor}>
      <StyledLocalizedLink to="/">
        <VigorLogo height={showBackgroundColor ? 35 : 55} inverted={true} primary={true} horizontal={true} variant={'primaryHorizontalInverted'}/>
      </StyledLocalizedLink>
      <Spacer />
      <Nav {...props}/>
    </Wrapper>
  );
};

export default Toolbar;
