import React, { HTMLAttributes, AnchorHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import TelegramIcon from "../../assets/svgs/telegram.svg";
import InstagramIcon from "../../assets/svgs/instagram.svg";
import FacebookIcon from "../../assets/svgs/facebook.svg";
import TwitterIcon from "../../assets/svgs/twitter-logo.svg";
import RedditIcon from "../../assets/svgs/reddit-logo.svg";
import GitlabLogo from "../../assets/svgs/gitlab.svg";
import PdfIcon from "../../assets/svgs/pdf.svg";
import BadgeIconSrc from "../../assets/images/vigor_protocol_logo_400x400.png";
import LocalizedLink from "../LocalizedLink";
import { media } from "../../utils/breakpoints";

const Wrapper = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.bgLight};
  padding: 0 0 51px 0;
`
);

const NavBlock = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 51px 0 59px 0;

  ${media.lessThan(`sm-max`)} {
    margin: 32px 0 16px 0;
  }
`;

const linkStyles = css`
  font-size: 15px;
  line-height: 19px;
  &:not(:last-of-type) {
    margin: 0 48px 0 0;
  }

  ${media.lessThan(`sm-max`)} {
    flex: 0 50%;
    text-align: center;
    margin: 0 0 16px 0;

    &:not(:last-of-type) {
      margin: 0 0 16px 0;
    }
  }
`;
const StyledLink = styled.a`
  ${linkStyles}
`;
const StyledLocalizedLink = styled(LocalizedLink)`
  ${linkStyles}
`;

const Header = styled.h3(
  ({ theme }) => `
	color: ${theme.colors.bgLightest};
	font-size: 27px;
	font-weight: bold;
	line-height: 33px;
  text-align: center;
  margin: 0 0 40px 0;
`
);

const SubHeader = styled.h4(
  ({ theme }) => `
	color: ${theme.colors.primaryLighter};
	font-size: 16px;
	font-weight: bold;
  line-height: 19px;
  margin: 0 0 16px 0;

  ${media.lessThan(`sm-max`)} {
    margin: 32px 0;
  }
`
);
const SubSectionDivider = styled.div`
  height: 48px;

  ${media.lessThan(`sm-max`)} {
    display: none;
  }
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${media.lessThan(`sm-max`)} {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div<any>(
  (props) => `
  flex: 1 0 auto;
  width: 33.3333%;
  display: flex;
  flex-direction: column;
  align-items: ${props.align || `center`};

  ${media.lessThan(`sm-max`)} {
    ${
      typeof props.mobileOrder === `number`
        ? `order: ${props.mobileOrder};`
        : ``
    }
    align-items: center;
    width: 100%;
  }
`
);

const Block = styled.div<any>(
  (props) => `
  display: flex;
  flex-direction: column;
  align-items: ${props.align || `center`};
  margin: ${props.margin || `0`}

  ${media.lessThan(`sm-max`)} {
    align-items: ${props.mobileAlign || props.align || `center`};
    margin: 0;
    padding: 0 19px;
    width: 420px;
    max-width: 100%;
  }
`
);

const LinkWithIconWrapper = styled.span<any>(
  (props) => `
  display: inline-flex;
  align-items:center;
  height: 35px;
  & a {
    margin: ${props.anchorMargin}
  }

  ${media.lessThan(`sm-max`)} {
    ${props.mobileHidden ? `display: none;` : ``}
  }
`
);
const LinkWithIcon: React.FC<
  {
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    mobileHidden?: boolean;
  } & AnchorHTMLAttributes<any>
> = (props) => {
  const { iconLeft, iconRight, mobileHidden = false, ...anchorProps } = props;
  const anchorMargin = iconLeft ? `0 0 0 16px` : iconRight ? `0 16px 0 0` : `0`;

  return (
    <LinkWithIconWrapper
      mobileHidden={mobileHidden}
      anchorMargin={anchorMargin}
    >
      {iconLeft}
      <a target="_blank" rel="noopener noreferrer" {...anchorProps} />
      {iconRight}
    </LinkWithIconWrapper>
  );
};

const HorizontalFlex = styled.div`
  display: flex;
  justify-content: center;
`;

const Disclaimer = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: center;
  max-width: 90%;
  width: 640px;
  font-size: 12px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.bgLightest};
`;

const SocialBar = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0 32px 0;

  & > a:not(:last-child) {
    margin: 0 28px 0 0;
  }

  ${media.lessThan(`sm-max`)} {
    margin: 64px 0 32px 0;
  }
`;

const CopyrightNotice = styled.div`
  color: ${(p) => p.theme.colors.bgLightest};
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
`;

const LegalLinks = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => p.theme.colors.whiteDarkened};
`;

const Footer: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <NavBlock>
        <StyledLocalizedLink to="/features">
          {t(`features`)}
        </StyledLocalizedLink>
        <StyledLocalizedLink to="/community">
          {t(`community`)}
        </StyledLocalizedLink>
        <StyledLocalizedLink to="/faq">{t(`faqShort`)}</StyledLocalizedLink>
        <StyledLink
          title={t(`docs`)}
          href="https://docs.vigor.ai"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t(`docs`)}
        </StyledLink>
      </NavBlock>
      <Header>{t(`footer.welcome`)}</Header>
      <ColumnsWrapper>
        <Column align="flex-end" mobileOrder={1}>
          <Block align="flex-start" mobileAlign="flex-start">
            <SubHeader>{t(`footer.headerTGPublic`)}</SubHeader>
            <LinkWithIcon
              href="https://t.me/vigorprotocol"
              iconLeft={<TelegramIcon />}
            >
              {t(`footer.linkTGVigorstablecoin`)}
            </LinkWithIcon>
            <LinkWithIcon mobileHidden />
            <LinkWithIcon mobileHidden />
            <SubSectionDivider />
            <SubHeader>{t(`footer.headerTGPWG`)}</SubHeader>
            <LinkWithIcon
              href="https://t.me/vigordacgovernance"
              iconLeft={<TelegramIcon />}
            >
              {t(`footer.linkTGVigorGov`)}
            </LinkWithIcon>
            <LinkWithIcon
              href="https://t.me/VIGORdev"
              iconLeft={<TelegramIcon />}
            >
              {t(`footer.linkTGVigorDev`)}
            </LinkWithIcon>
          </Block>
        </Column>
        <Column mobileOrder={0}>
          <Block align="center" margin="48px 0 0 0">
            <img
              src={BadgeIconSrc}
              alt={"Vigor low volatility"}
              width={240}
              height={240}
            />
          </Block>
        </Column>
        <Column align="flex-start" mobileOrder={2}>
          <Block align="flex-end" mobileAlign="flex-start">
            <SubHeader>{t(`footer.headerDACClients`)}</SubHeader>
            <LinkWithIcon target="_self" href="https://dac.vigor.ai">
              {t(`footer.linkVigorAI`)}
            </LinkWithIcon>
            <SubSectionDivider />
            <SubHeader>{t(`footer.headerDocs`)}</SubHeader>
            <LinkWithIcon href="/vigor.pdf" iconRight={<PdfIcon />}>
              {t(`footer.linkDocsWPen`)}
            </LinkWithIcon>
          </Block>
        </Column>
      </ColumnsWrapper>
      <SocialBar>
        <a
          href="https://twitter.com/vigorprotocol"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon height={20} />
        </a>
        <a
          href="https://www.reddit.com/r/vigor"
          target="_blank"
          rel="noopener noreferrer"
        >
          <RedditIcon height={20} />
        </a>
        <a
          href="https://gitlab.com/vigorstablecoin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GitlabLogo height={24} />
        </a>
        <a
          href="https://t.me/vigorprotocol"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TelegramIcon height={22} style={{ margin: `3px 0 0 0` }} />
        </a>
        <a
          href="https://www.facebook.com/groups/VigorDAC"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon height={24}/>
        </a>
        <a
          href="https://www.instagram.com/vigorprotocol"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon height={22}/>
        </a>
      </SocialBar>
      {/* <LegalLinks>
        <a href="../VIGOR_terms_conditions.pdf" target="_blank">{t('footer.termsConditions')}</a>
        <span> | </span>
        <a href="../VIGOR_privacy_policy.pdf" target="_blank">{t('footer.privacyPolicy')}</a>
      </LegalLinks> */}
      <Disclaimer>{t(`footer.disclaimer`)}</Disclaimer>
    </Wrapper>
  );
};

export default Footer;
